import { useSession } from 'next-auth/react';
import useSWR from 'swr';

import { useFetch } from '@packages/eh-utils/api';
import { ApiConfigs } from '@packages/eh-utils/api/types';
import { getNotificationServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

type TGetCandidateNotificationSettingsParams = void;

export type TGetCandidateNotiticationSettingsResponse = {
  data: {
    delivery_settings: {
      ats_direct_message: string[];
    };
  };
};

type TGetCandidateNotificationSettingsProps = Pick<
  ApiConfigs<
    TGetCandidateNotiticationSettingsResponse,
    TGetCandidateNotificationSettingsParams
  >,
  'onCompleted' | 'onFailed'
>;

const useGetCandidateNotificationSettings = ({
  onCompleted,
  onFailed,
}: TGetCandidateNotificationSettingsProps) => {
  const { status } = useSession();

  const { fetcher, loading } = useFetch<
    TGetCandidateNotiticationSettingsResponse,
    TGetCandidateNotificationSettingsParams
  >({
    endpoint: `${getNotificationServiceDirectApiHost()}/api/v1/user-settings/delivery`,
    method: 'GET',
    onCompleted,
    onFailed,
  });

  const { data, mutate } = useSWR<TGetCandidateNotiticationSettingsResponse>(
    status === 'authenticated'
      ? { key: 'getCandidateNotificationSettings' }
      : undefined,
    () => fetcher()
  );

  return {
    data,
    refresh: mutate,
    loading,
  };
};

export default useGetCandidateNotificationSettings;
