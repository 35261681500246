import React from 'react';
import { useRouter } from 'next/router';

import { TParsedNotificationCandidateShortlistedData } from 'src/modules/notifications/types';

import NotificationItemLayout from '../shared/NotificationItemLayout';

type CandidateShortlistedProps = {
  data: TParsedNotificationCandidateShortlistedData;
};

const CandidateShortlisted = ({ data }: CandidateShortlistedProps) => {
  const { title, createdAt, options, type } = data;
  const { organisationId, jobId } = options.data;
  const router = useRouter();

  const onNavigate = async () => {
    router.push({
      pathname: `/sm_jobs/${jobId}`,
    });
  };

  return (
    <NotificationItemLayout
      type={type}
      message={title}
      createdAt={createdAt}
      onNavigate={onNavigate}
      organisationId={organisationId}
    />
  );
};

export default CandidateShortlisted;
