import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { Box } from '@hero-design/react';

import generateGetURL from '@packages/eh-utils/urlGenerator';
import { mediaMaxQueries, themeGet } from '@packages/hero-theme/utils';

import JobSearch from './JobSearch';
import { filterCountryOptions } from './helpers';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${themeGet('space.small')}px;

  padding: 80px 0px;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  ${mediaMaxQueries.xl} {
    padding: 30px 0px;
  }
  ${mediaMaxQueries.md} {
    flex-direction: column;
    padding: 20px 0px;
    text-align: center;
  }
  ${mediaMaxQueries.sm} {
    padding: 20px 0px;
  }
`;

const SwagHoldingText = styled.p`
  font-size: 76px;
  line-height: 84px;
  font-weight: 600;

  ${mediaMaxQueries.lg} {
    font-size: 54px;
    line-height: 62px;
  }
  ${mediaMaxQueries.md} {
    font-size: 42px;
    line-height: 50px;
  }
`;

interface TSearchFormProps {
  query: string;
  vendorLocationId?: number;
  vendorLocationName?: string;
  countryCode?: string;
  radius?: number;
}

const Header = () => {
  const router = useRouter();

  const onSearch = useCallback(
    (searchQueries: any) => {
      const removedAnywhere = filterCountryOptions(searchQueries);
      const pathToJobSearch = generateGetURL('/search', removedAnywhere);

      router.push(pathToJobSearch);
    },
    [router]
  );

  const { handleSubmit, control, setValue, getValues, reset } =
    useForm<TSearchFormProps>({
      mode: 'all',
      defaultValues: {
        query: undefined,
        vendorLocationId: undefined,
        vendorLocationName: undefined,
        countryCode: 'anywhere',
        radius: undefined,
      },
    });

  useEffect(() => {
    if (router?.isReady) {
      // temporary fix for country code since we are using SG as default but BE return it as SI
      const checkCountryCode = router.locale === 'SG' ? 'SI' : router.locale;

      setValue('countryCode', checkCountryCode);
    }
  }, [router?.isReady, router.locale]);

  return (
    <HeaderWrapper>
      <Box>
        <SwagHoldingText>Find the jobs you dream about!</SwagHoldingText>
      </Box>
      <JobSearch
        onSubmit={handleSubmit(onSearch)}
        control={control}
        setValue={setValue}
        getValues={getValues}
        reset={reset}
      />
    </HeaderWrapper>
  );
};

export default Header;
