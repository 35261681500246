import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';

import { Box, Button, Portlet, Typography } from '@hero-design/react';

import useFetchSalaryRange from 'src/modules/CareersPage/components/SalaryBenchmark/hooks/useFetchSalaryRange';
import useFetchUserProfile from 'src/modules/User/hooks/useFetchUserProfile';

const EarningPotentialTile: React.FC = () => {
  const theme = useTheme();
  const router = useRouter();
  const { userProfileData, isFetchingUserProfile } = useFetchUserProfile();
  const countryCode = userProfileData?.data.country_code;
  const locationName = [userProfileData?.data.city, countryCode]
    .filter(Boolean)
    .join(', ');
  const jobTitle = `.${userProfileData?.data.headline || ''}`;

  const {
    loading: loadingSalaryRange,
    data: salaryRange,
    fetcher: fetchSalaryRange,
  } = useFetchSalaryRange({
    queryParams: {
      occupation: jobTitle,
      country: 'AU',
      exactMatch: true,
    },
  });

  useEffect(() => {
    fetchSalaryRange();
  }, []);

  const redirectToCareerPage = () => {
    router.push('/maximise_your_potential');
  };

  const potentialSalary = salaryRange?.answer.p75.toLocaleString();

  return (
    <Portlet
      title="Your earning potential:"
      sx={{
        boxShadow: `0px 2px 4px ${theme.colors.black}1F`,
        backgroundImage: `url('/assets/images/earning-potential-tile-decoration.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'top right',
      }}
      loading={isFetchingUserProfile || loadingSalaryRange}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 'medium',
          alignItems: 'baseline',
        }}
      >
        <Typography.Title level={2} sx={{ fontSize: 'xxxxxlarge' }}>
          {potentialSalary ? `$${potentialSalary}` : <span>&mdash;</span>}
        </Typography.Title>
        <Typography.Text fontWeight="semi-bold" fontSize={16}>
          {`in ${locationName}`}
        </Typography.Text>
      </Box>

      <Button
        text="Find out more"
        variant="outlined"
        intent="primary"
        sx={{
          mt: theme.space.medium,
          fontWeight: 'semi-bold',
        }}
        onClick={redirectToCareerPage}
      />
    </Portlet>
  );
};

export default EarningPotentialTile;
