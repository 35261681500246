import React, { useCallback } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';

import { Box, Typography } from '@hero-design/react';

import { SelectInput, TextInput } from '@packages/hero-theme/form';
import { mediaMaxQueries, themeGet } from '@packages/hero-theme/utils';
import useFetchPermissions from '@shared/hooks/useFetchPermissions';
import useLocation from '@shared/hooks/useLocation';

import AutoCompleteJobCompanyInput from '../CandidateJobSearch/AutoCompleteJobCompanyInput';
import { RadiusOptions } from '../CandidateJobSearch/const';

const CountryLocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: ${themeGet('space.small')}px;

  ${mediaMaxQueries.md} {
    flex-direction: column;
    gap: 0px;
  }
`;

interface TSearchFormProps {
  query: string;
  vendorLocationId?: number;
  vendorLocationName?: string;
  countryCode?: string;
  radius?: number;
}

type JobSearchFormProps = {
  location: ReturnType<typeof useLocation>;
  control: Control<TSearchFormProps>;
  setValue: UseFormSetValue<TSearchFormProps>;
};

const JobSearchForm = ({ location, control, setValue }: JobSearchFormProps) => {
  const {
    disableLocation,
    onLocationInputChange,
    locationOptions,
    countryOptions,
    isLoadingCountries,
    isLoadingLocationData,
  } = location;

  const { permissionsData } = useFetchPermissions();
  const swagSeoStrategy2024Enabled =
    permissionsData?.data.swag_seo_strategy_2024_enabled || false;

  const onCountryChange = useCallback(countryCode => {
    if (!countryCode || countryCode === 'anywhere') {
      setValue('radius', undefined);
    }

    setValue('countryCode', countryCode);
  }, []);

  return (
    <Box pt="small">
      <Controller<TSearchFormProps, 'query'>
        name="query"
        control={control}
        render={({ field, fieldState }) => (
          <AutoCompleteJobCompanyInput
            field={field}
            fieldState={fieldState}
            inputProps={{
              placeholder: 'Search by job title',
              prefix: 'search-outlined',
            }}
            labelProps={{
              text: <Typography.Text>What</Typography.Text>,
            }}
            extraProps={{
              noResultText:
                'Enter at least 3 characters for job title searches',
            }}
          />
        )}
      />

      <CountryLocationWrapper>
        <Box sx={{ flex: 1, width: '100%' }}>
          <Controller<TSearchFormProps, 'countryCode'>
            name="countryCode"
            control={control}
            render={({ field, fieldState }) => (
              <SelectInput
                field={{ ...field, onChange: onCountryChange }}
                fieldState={fieldState}
                inputProps={{
                  placeholder: 'Country',
                  loading: isLoadingCountries,
                  options: countryOptions,
                }}
                extraProps={{
                  'data-test-id': 'country-field',
                }}
                labelProps={{
                  text: <Typography.Text>Where</Typography.Text>,
                }}
              />
            )}
          />
        </Box>
        <Box sx={{ flex: 1, width: '100%' }}>
          <Controller<TSearchFormProps, 'vendorLocationId'>
            name="vendorLocationId"
            control={control}
            render={({ field, fieldState }) => (
              <SelectInput
                field={field}
                fieldState={fieldState}
                inputProps={{
                  disabled: disableLocation,
                  placeholder: 'Enter city...',
                  loading: isLoadingLocationData,
                  options: locationOptions,
                }}
                extraProps={{
                  queryable: true,
                  onQueryChange: onLocationInputChange,
                  'data-test-id': 'location-field',
                }}
              />
            )}
          />
        </Box>
        {swagSeoStrategy2024Enabled ? (
          <Box sx={{ flex: 1, width: '100%' }}>
            <Controller<TSearchFormProps, 'radius'>
              name="radius"
              control={control}
              render={({ field, fieldState }) => (
                <SelectInput
                  field={field}
                  fieldState={fieldState}
                  inputProps={{
                    disabled: disableLocation,
                    placeholder: 'Radius',
                    options: RadiusOptions,
                  }}
                  extraProps={{
                    'data-test-id': 'radius-field',
                  }}
                />
              )}
            />
          </Box>
        ) : null}
      </CountryLocationWrapper>
      <Box style={{ display: 'none' }}>
        <Controller<TSearchFormProps, 'vendorLocationName'>
          name="vendorLocationName"
          control={control}
          render={({ field, fieldState }) => (
            <TextInput field={field} fieldState={fieldState} />
          )}
        />
      </Box>
    </Box>
  );
};

export default JobSearchForm;
