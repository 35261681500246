import React, { useEffect } from 'react';
import { NextPage } from 'next';
import { useSession } from 'next-auth/react';

import {
  TRACK_ATS_EH_JOB_BOARD_VISIT,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

import CareerAdviceBanner from './CareerAdviceBanner';
import Header from './Header';
import RebrandingWrapper from './RebrandingWrapper';
import SmartMatchBanner from './SmartMatchBanner';
import SwagappDownloadBanner from './SwagappDownloadBanner';
import SwagProfileBanner from './SwagProfileBanner';

const LandingPage: NextPage = () => {
  const { track } = useMixpanelTracking();
  const { status } = useSession();

  useEffect(() => {
    if (status === 'authenticated') {
      track(TRACK_ATS_EH_JOB_BOARD_VISIT, {
        'Is Logged In': status === 'authenticated',
        landing_page_version: 2,
      });
    }
  }, [status, track]);

  return (
    <RebrandingWrapper>
      <Header />
      <SwagProfileBanner />
      <SmartMatchBanner />
      <CareerAdviceBanner />

      <div>
        <SwagappDownloadBanner />
      </div>
    </RebrandingWrapper>
  );
};

export default LandingPage;
