import React from 'react';
import { useRouter } from 'next/router';

import { TParsedNotificationCandidateMovementData } from 'src/modules/notifications/types';

import NotificationItemLayout from '../shared/NotificationItemLayout';

type CandidateMovementProps = {
  data: TParsedNotificationCandidateMovementData;
};

const CandidateMovement = ({ data }: CandidateMovementProps) => {
  const { createdAt, options, type, body } = data;
  const { organisationId } = options.data;
  const router = useRouter();
  const onNavigate = () => {
    router.push({
      pathname: `/applications`,
    });
  };

  return (
    <NotificationItemLayout
      type={type}
      message={body}
      createdAt={createdAt}
      onNavigate={onNavigate}
      organisationId={organisationId}
    />
  );
};

export default CandidateMovement;
