import React from 'react';
import { useTheme } from 'styled-components';

import { Box, Divider } from '@hero-design/react';

import { NOTIFICATION_TYPE } from 'src/modules/notifications/constants';
import { TParsedNotificationSupportedData } from 'src/modules/notifications/types';

import CandidateMovement from './CandidateMovement';
import CandidateShortlisted from './CandidateShortlisted';
import DirectMessage from './DirectMessage';

type NotificationItems = {
  data: TParsedNotificationSupportedData;
};
const NotificationItems = ({ data }: NotificationItems) => {
  const theme = useTheme();
  const renderItem = () => {
    switch (data.type) {
      case NOTIFICATION_TYPE.ATS_DIRECT_MESSAGE:
        return <DirectMessage data={data} />;
      case NOTIFICATION_TYPE.ATS_CANDIDATE_SHORTLISTED:
        return <CandidateShortlisted data={data} />;
      case NOTIFICATION_TYPE.ATS_CANDIDATE_MOVEMENT:
        return <CandidateMovement data={data} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Box
        sx={{
          pb: 'medium',
          pt: 'medium',
          display: 'flex',
          flexDirection: 'row',
          columnGap: `${theme.space.medium}px`,
          bgColor: 'defaultLightBackground',
        }}
      >
        {renderItem()}
      </Box>
      <Divider />
    </>
  );
};

export default NotificationItems;
