import React from 'react';
import { useRouter } from 'next/router';

import useCreateOrGetMessageThread from 'src/modules/directMessages/hooks/useCreateOrGetMessageThread';
import { TParsedNotificationMessageData } from 'src/modules/notifications/types';

import NotificationItemLayout from '../shared/NotificationItemLayout';

type DirectMessageProps = {
  data: TParsedNotificationMessageData;
};

const DirectMessage = ({ data }: DirectMessageProps) => {
  const { title, createdAt, options, type } = data;
  const { organisationId } = options.data;
  const router = useRouter();
  const { fetcher: createOrGetMessageThread, loading } =
    useCreateOrGetMessageThread();

  const onNavigate = async () => {
    const threadData = await createOrGetMessageThread({
      message_threadable_id: data.options.data.applicationId,
      message_threadable_type: 'CandidateJob',
    });

    router.push({
      pathname: '/messages',
      query: {
        thread_id: threadData.data.thread_document_id,
      },
    });
  };

  return (
    <NotificationItemLayout
      type={type}
      message={title}
      createdAt={createdAt}
      loading={loading}
      onNavigate={onNavigate}
      organisationId={organisationId}
    />
  );
};

export default DirectMessage;
