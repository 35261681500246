import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';

import { CheckboxInput } from '@packages/hero-theme/form';

export type TNotificationSettingsForm = {
  newMessageSettings: string[];
};

export type NotificationSettingsFormProps = {
  methods: UseFormReturn<TNotificationSettingsForm>;
  handleOnChange: (value: string[]) => void;
};

const NOTIFICATION_SETTING_TYPES = [
  { value: 'email', text: 'Email' },
  { value: 'mobile', text: 'Mobile' },
];

const NotificationSettingsForm = ({
  methods,
  handleOnChange,
}: NotificationSettingsFormProps) => (
  <FormProvider {...methods}>
    <Controller<TNotificationSettingsForm, 'newMessageSettings'>
      name="newMessageSettings"
      render={({ field, fieldState }) => {
        const { onChange: onChangeField } = field;

        return (
          <CheckboxInput
            field={{
              ...field,
              onChange: value => {
                handleOnChange(value);
                onChangeField(value);
              },
            }}
            fieldState={fieldState}
            inputProps={{
              options: NOTIFICATION_SETTING_TYPES,
              layout: 'horizontal',
            }}
          />
        );
      }}
    />
  </FormProvider>
);

export default NotificationSettingsForm;
