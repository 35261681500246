import styled from 'styled-components';

import { Box, Button, Grid, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { mediaMaxQueries, themeGet } from '@packages/hero-theme/utils';
import {
  TRACK_LANDING_PAGE_CAREER_ADVICE_CTA_CLICK,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

const AdviceTitleWrapper = styled.div`
  font-size: ${themeGet('fontSizes.10xlarge')}px;
  line-height: ${themeGet('lineHeights.10xlarge')}px;
  font-weight: ${themeGet('fontWeights.semiBold')};
  margin-bottom: ${themeGet('space.medium')}px;

  ${mediaMaxQueries.xl} {
    font-size: ${themeGet('fontSizes.8xlarge')}px;
    line-height: ${themeGet('lineHeights.8xlarge')}px;
  }

  ${mediaMaxQueries.md} {
    font-size: ${themeGet('fontSizes.6xlarge')}px;
    line-height: ${themeGet('lineHeights.6xlarge')}px;
  }
`;

const ConversationImage = styled(Image)`
  max-width: 100%;
  max-height: 440px;
  margin-left: ${themeGet('space.large')}px;
  width: initial !important;

  ${mediaMaxQueries.md} {
    margin-left: 0px;
  }

  ${mediaMaxQueries.sm} {
    width: 90% !important;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-left: ${themeGet('space.large')}px;
  padding-right: ${themeGet('space.large')}px;
  text-align: center;

  ${mediaMaxQueries.md} {
    padding-right: 0px;
  }

  ${mediaMaxQueries.sm} {
    padding-right: ${themeGet('space.large')}px;
  }
`;

const CareerAdviceBanner = () => {
  const { track } = useMixpanelTracking();

  return (
    <Grid sx={{ mb: 'medium' }}>
      <Grid.Row gutter={['medium', 'medium']} sx={{ placeContent: 'center' }}>
        <Grid.Col span={[24, 20, 12, 12, 12]} sx={{ mb: 'xlarge' }}>
          <ImageWrapper>
            <ConversationImage
              src="/assets/images/swag-expert-conversation.png"
              alt="swag features"
              layout="responsive"
            />

            <Image
              src="/assets/images/work-habits-headline.svg"
              alt="work habits article"
              layout="responsive"
              style={{
                position: 'absolute',
                right: '25%',
                top: '68%',
                width: 'fit-content',
                height: '25%',
              }}
            />

            <Image
              src="/assets/images/interview-tips-headline.svg"
              alt="interview tips article"
              style={{
                position: 'absolute',
                right: '5%',
                top: '84%',
                width: 'fit-content',
                height: '25%',
              }}
            />
          </ImageWrapper>
        </Grid.Col>

        <Grid.Col span={[24, 24, 12, 12, 12]} sx={{ alignSelf: 'center' }}>
          <Box pl="xlarge" pr="xlarge">
            <AdviceTitleWrapper>Advice from the experts</AdviceTitleWrapper>

            <Typography.Text fontSize={16} sx={{ mb: 'xlarge' }}>
              Level-up your career and take control of your finances with our
              latest resources, tips and advice — created just for you.
            </Typography.Text>

            <Button
              text="Take a look"
              onClick={() => {
                track(TRACK_LANDING_PAGE_CAREER_ADVICE_CTA_CLICK);
                window.open(
                  'https://swagapp.com/jobs/resources/',
                  '_blank',
                  'noopener noreferrer'
                );
              }}
              size="large"
            />
          </Box>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default CareerAdviceBanner;
