import styled from 'styled-components';

import { jobsPalette } from '@hero-design/colors';
import { Box, Grid, theme, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { mediaMaxQueries, themeGet } from '@packages/hero-theme/utils';
import AppDownloadGroupButton from '@shared/AppDownloadButtonGroup';
import QRCode from '@shared/QRCode';

const StyledWrapper = styled.div`
  border-radius: 50px;
  background-color: ${jobsPalette.hitPink};
  margin-bottom: ${themeGet('space.xxxxlarge')}px;
  background-image: url('/assets/images/line-pattern.svg');
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;

  ${mediaMaxQueries.lg} {
    margin: ${themeGet('space.large')}px;
  }

  ${mediaMaxQueries.md} {
    border-radius: ${themeGet('radii.large')}px;
    margin: 0;
  }
`;

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${themeGet('space.small')}px;
  padding: ${themeGet('space.xxlarge')}px;

  ${mediaMaxQueries.md} {
    align-items: center;
  }
`;

const HideInMobileView = styled.div`
  ${mediaMaxQueries.sm} {
    display: none;
  }
`;

const SwagappDownloadBanner = () => (
  <StyledWrapper>
    <Grid sx={{ p: 0 }}>
      <Grid.Row>
        <Grid.Col span={[24, 24, 10, 10, 10]}>
          <DownloadWrapper>
            <Typography.Title>Get the app</Typography.Title>

            <HideInMobileView>
              <Typography.Text fontWeight="light">
                Scan this QR code to download
              </Typography.Text>

              <Box sx={{ mb: theme.space.medium }}>
                <QRCode />
              </Box>
            </HideInMobileView>

            <AppDownloadGroupButton
              badgeSize={{ width: 150, height: 43 }}
              direction="row"
            />
          </DownloadWrapper>
        </Grid.Col>

        <Grid.Col span={[24, 24, 14, 14, 14]}>
          <Box
            sx={{
              position: 'relative',
              mt: theme.space.xxlarge,
              ml: '18%',
              mr: '20%',
              mb: '-6px',
            }}
          >
            <Image
              src="/assets/images/swag-device.svg"
              alt="swag job summary"
              width={1431}
              layout="responsive"
              sx={{ height: 'fit-content' }}
            />

            <Image
              src="/assets/images/swag-device-floating-card-1.svg"
              alt="swag-background-dots05"
              style={{
                position: 'absolute',
                left: '-20%',
                top: '57%',
                width: 'fit-content',
                height: '15%',
              }}
            />

            <Image
              src="/assets/images/swag-device-floating-card-2.svg"
              alt="swag-background-dots05"
              style={{
                position: 'absolute',
                right: '-10%',
                top: '30%',
                width: 'fit-content',
                height: '8%',
              }}
            />

            <Image
              src="/assets/images/swag-device-floating-card-3.svg"
              alt="swag-background-dots05"
              style={{
                position: 'absolute',
                right: '-20%',
                top: '75%',
                width: 'fit-content',
                height: '10%',
              }}
            />
          </Box>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  </StyledWrapper>
);

export default SwagappDownloadBanner;
