import React, { Fragment, useMemo, useState } from 'react';
import useSWR from 'swr';
import styled from 'styled-components';

import {
  Box,
  Button,
  Card,
  Modal,
  Spinner,
  Tag,
  Typography,
} from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { useFetch } from '@packages/eh-utils/api';
import { getDirectMainAppHost } from '@packages/eh-utils/browserEnv';
import { mediaMaxQueries } from '@packages/hero-theme/utils';
import LinkButton from '@shared/LinkButton';
import useWindowSize from '@shared/hooks/useWindowSize';

import { PaginatedResponse } from 'src/modules/CareersPage/types';

import useFetchUserProfile from '../../hooks/useFetchUserProfile';

import { genProfileHealthContent } from './ProfileHealth/utils';

const ComponentWrapper = styled(Box)`
  width: 70%;

  ${mediaMaxQueries.xl} {
    width: 80%;
  }

  ${mediaMaxQueries.md} {
    width: auto;
  }
  && {
    margin-left: auto;
    margin-right: auto;
  }
`;

const useGetLatestEmploymentHistories = () => {
  const { fetcher } = useFetch<
    {
      data: PaginatedResponse<{
        id: number;
        title: string;
        start_date: string;
        end_date: string;
      }>;
    },
    unknown
  >({
    endpoint: `${getDirectMainAppHost()}/api/v1/candidate_profiles/latest_terminated_member/employment_histories`,
    method: 'GET',
    withSWR: true,
  });

  const { data, isValidating } = useSWR(
    'latestEmploymentHistory',
    () => fetcher(),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    }
  );

  return {
    data: data?.data.items,
    isLoading: isValidating,
  };
};

const usePopulateLatestEmploymentHistories = (options?: {
  onComplete: VoidFunction;
  onFailed: VoidFunction;
}) => {
  const { fetcher, loading } = useFetch({
    endpoint: `${getDirectMainAppHost()}/api/v1/candidate_profiles/latest_terminated_member/employment_histories/populate`,
    method: 'POST',
    onCompleted: options?.onComplete,
    onFailed: options?.onFailed,
  });

  return {
    fetcher,
    isLoading: loading,
  };
};

type Props = {
  firstName: string;
  profileStrengthScore?: number;
  smartMatchCount?: number;
};

export const EHUserHome = ({
  smartMatchCount = 0,
  firstName,
  profileStrengthScore,
}: Props) => {
  const { isSmallScreen } = useWindowSize();
  const { userProfileData } = useFetchUserProfile();

  const [status, setStatus] = useState<
    'pending' | 'populating' | 'populated' | 'failed' | 'done'
  >('pending');

  const closeModal = () => setStatus('done');

  const { data, isLoading } = useGetLatestEmploymentHistories();
  const { fetcher: populate, isLoading: isPopulating } =
    usePopulateLatestEmploymentHistories({
      onComplete: () => {
        setStatus('populated');
      },
      onFailed: () => {
        setStatus('failed');
      },
    });

  const profileUrl = useMemo(() => {
    if (userProfileData?.data.friendly_id) {
      return `/user/${userProfileData.data.friendly_id}`;
    }

    return '';
  }, [userProfileData?.data.friendly_id]);

  const { text, tagIntent } = genProfileHealthContent(
    profileStrengthScore ?? 0
  );

  return (
    <Box
      sx={{
        width: '100%',
        mb: 'xlarge',
        bgColor: 'defaultLightBackground',
      }}
    >
      <ComponentWrapper
        pt="medium"
        pb={isSmallScreen ? 'medium' : 'xlarge'}
        pl={isSmallScreen ? 'medium' : undefined}
        pr={isSmallScreen ? 'medium' : undefined}
        style={{
          display: 'flex',
          boxSizing: 'border-box',
          flexDirection: isSmallScreen ? 'column-reverse' : 'row',
        }}
      >
        <Box
          pr={isSmallScreen ? undefined : 'large'}
          sx={{ alignSelf: 'center' }}
        >
          <Typography.Title level={1} sx={{ mb: 'small' }}>
            Welcome back, {firstName}
          </Typography.Title>
          <Typography.Text intent="body">
            With Swag, securely access your employment info anytime, anywhere.
            Plus, enable SmartMatch to connect with 300,000+ employers looking
            for talent like you.
          </Typography.Text>
          <LinkButton
            href="/search"
            variant="inline-text"
            size="large"
            text="Search for job"
            rightIcon="arrow-right"
          />
        </Box>
        {!isSmallScreen && (
          <Image
            src="/assets/images/setup-profile-complete.png"
            alt="Welcome image"
            style={{
              width: '50%',
              margin: 'auto',
              maxWidth: 340,
            }}
          />
        )}
      </ComponentWrapper>
      <ComponentWrapper
        pl={isSmallScreen ? 'medium' : undefined}
        pr={isSmallScreen ? 'medium' : undefined}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: 'medium',
            flexDirection: isSmallScreen ? 'column' : 'row',
          }}
        >
          <Card
            sx={{
              flex: 6,
            }}
          >
            <Card.Content
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography.Title
                level={5}
                sx={{
                  mb: 'small',
                }}
              >
                Add your verified employment reference to your profile
              </Typography.Title>
              <Typography.Text
                fontSize={12}
                intent="subdued"
                sx={{
                  mb: 'medium',
                }}
              >
                Automatically populate your profile through Employment Hero.
                We’ll use your name, contact details, verified employment
                history and certifications to build your Swag profile.
              </Typography.Text>
              <Box
                sx={{
                  boxShadow: 'default',
                  borderRadius: 'medium',
                  display: 'flex',
                  gap: 'large',
                  padding: 'medium',
                  mt: 'auto',
                  mb: isSmallScreen ? 'medium' : 'auto',
                }}
              >
                {isLoading && !data?.length && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <Typography.Text>Getting information</Typography.Text>
                    <Spinner />
                  </Box>
                )}
                {data?.map(item => (
                  <Fragment key={item.id}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 'small',
                        minWidth: 100,
                      }}
                    >
                      <Typography.Text>Position:</Typography.Text>
                      <Typography.Text>From:</Typography.Text>
                      <Typography.Text>To:</Typography.Text>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 'small',
                      }}
                    >
                      <Typography.Text>{item.title}</Typography.Text>
                      <Typography.Text>{item.start_date}</Typography.Text>
                      <Typography.Text>{item.end_date}</Typography.Text>
                    </Box>
                  </Fragment>
                ))}
              </Box>
              <Box>
                <Button
                  size="large"
                  text={isPopulating ? 'Adding...' : 'Add verified reference'}
                  icon="add"
                  loading={isPopulating}
                  onClick={() => populate()}
                />
              </Box>
            </Card.Content>
          </Card>
          <Box
            sx={{
              display: 'flex',
              gap: 'medium',
              flexDirection: 'column',
              flex: 4,
            }}
          >
            <Card
              sx={{
                flex: 1,
              }}
            >
              <Card.Content>
                <Typography.Title
                  level={5}
                  sx={{
                    mb: 'small',
                  }}
                >
                  {smartMatchCount > 0
                    ? 'You have matches'
                    : 'No Smart Matches'}
                </Typography.Title>
                <Typography.Text
                  fontSize={12}
                  intent="subdued"
                  sx={{
                    mb: 'medium',
                  }}
                >
                  {smartMatchCount > 0
                    ? `${smartMatchCount} employers are interested in your profile. View your top matches and decide which to apply for with just a click.`
                    : 'No employers are interested in your profile'}
                </Typography.Text>
                <LinkButton
                  href="/user/job_matches"
                  text="View top matches"
                  variant="outlined"
                />
              </Card.Content>
            </Card>
            <Card
              sx={{
                flex: 1,
              }}
            >
              <Card.Content>
                <Box
                  sx={{
                    mb: 'small',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 'small',
                  }}
                >
                  <Typography.Title level={5}>
                    Profile strength
                  </Typography.Title>
                  <Tag
                    intent={tagIntent}
                    text={text.toUpperCase()}
                    variant="outlined"
                  />
                </Box>
                <Typography.Text
                  fontSize={12}
                  intent="subdued"
                  sx={{
                    mb: 'medium',
                  }}
                >
                  The more detailed your profile, the better matches you’ll
                  receive. Finish filling out your profile now!
                </Typography.Text>
                <LinkButton
                  href={profileUrl}
                  text="Preview profile"
                  variant="outlined"
                />
              </Card.Content>
            </Card>
          </Box>
        </Box>
      </ComponentWrapper>
      {status === 'populated' && (
        <Modal.PopUp
          title="Your verified employment reference has been added"
          open
          variant="success"
          onClose={closeModal}
          footer={
            <>
              <Button text="Close" variant="text" onClick={closeModal} />
              <LinkButton href={profileUrl} text="View profile" />
            </>
          }
        />
      )}

      {status === 'failed' && (
        <Modal.PopUp
          title="Failed to add verified employment reference"
          open
          variant="warning"
          onClose={closeModal}
          footer={<Button text="Close" variant="text" onClick={closeModal} />}
        />
      )}
    </Box>
  );
};
