import { Box, theme } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import {
  TRACK_ATS_APP_STORE_CLICK,
  TRACK_ATS_PLAY_STORE_CLICK,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

const APP_STORE_HREF =
  'https://apps.apple.com/app/employment-hero-mobile/id1171253572';
const PLAY_STORE_HREF =
  'https://play.google.com/store/apps/details?id=com.ehlife';

const AppDownloadGroupButton = ({
  badgeSize,
  direction = 'column',
}: {
  badgeSize: { width: number; height: number };
  direction?: 'column' | 'row';
}) => {
  const { track } = useMixpanelTracking();

  const openLink = (
    link: string,
    trackEvent:
      | typeof TRACK_ATS_APP_STORE_CLICK
      | typeof TRACK_ATS_PLAY_STORE_CLICK
  ) => {
    track(trackEvent);
    window.open(link, '_blank');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: direction,
        alignItems: 'center',
        gap: theme.space.small,
      }}
    >
      <Box>
        <Image
          src="/assets/images/app-store-badge.svg"
          alt="App Store"
          width={badgeSize.width}
          height={badgeSize.height}
          onClick={() => openLink(APP_STORE_HREF, TRACK_ATS_APP_STORE_CLICK)}
          sx={{ width: 'fit-content' }}
        />
      </Box>

      <Image
        src="/assets/images/google-play-badge.svg"
        alt="Google play"
        width={badgeSize.width}
        height={badgeSize.height}
        onClick={() => openLink(PLAY_STORE_HREF, TRACK_ATS_PLAY_STORE_CLICK)}
        sx={{ width: 'fit-content' }}
      />
    </Box>
  );
};

export default AppDownloadGroupButton;
