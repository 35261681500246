import useSWR, { SWRConfiguration } from 'swr';

import { ApiConfigs, useFetch } from '@packages/eh-utils/api';
import { getSalaryGuideDirectApiHost } from '@packages/eh-utils/browserEnv';

import { SalaryRange } from '../types';

export type TFetchSalaryRangeParams = {
  occupation: string;
  country: string;
  residential_state?: string;
  seniority?:
    | 'all'
    | 'junior'
    | 'intermediate'
    | 'senior'
    | 'lead'
    | 'manager'
    | 'head'
    | 'director'
    | 'chief';
  employment_type?: 'all' | 'casual' | 'full-time' | 'part-time';
  industry?: string;
  aggregated_fields?: string;
};

type TUseFetchSalaryRangeResponse = SalaryRange;

type TUseFetchSalaryRangeProps = {
  configs?: SWRConfiguration;
  queryParams: TFetchSalaryRangeParams & {
    exactMatch: boolean;
  };
} & Pick<
  ApiConfigs<TUseFetchSalaryRangeResponse, TFetchSalaryRangeParams>,
  'onCompleted' | 'onFailed'
>;

const buildSalaryRangeParams = ({
  occupation,
  country,
  residential_state,
  seniority,
  employment_type,
  industry,
  aggregated_fields,
  exactMatch,
}: TFetchSalaryRangeParams & {
  exactMatch: boolean;
}): TFetchSalaryRangeParams => {
  const jobTitle = (() => {
    if (!occupation) {
      return '';
    }

    return exactMatch ? occupation : `.${occupation}`;
  })();

  return {
    occupation: jobTitle,
    country: country || 'AU',
    seniority: seniority || 'all',
    employment_type: employment_type || 'all',
    residential_state: residential_state || 'all',
    industry: industry || 'all',
    aggregated_fields: aggregated_fields || 'seniority,industry',
  };
};

const useFetchSalaryRange = ({
  onCompleted,
  onFailed,
  configs = {},
  queryParams,
}: TUseFetchSalaryRangeProps) => {
  const { fetcher, loading } = useFetch<
    TUseFetchSalaryRangeResponse,
    TFetchSalaryRangeParams
  >({
    method: 'GET',
    endpoint: `${getSalaryGuideDirectApiHost()}/salary_range`,
    withSWR: true,
    onCompleted,
    onFailed,
  });
  const { occupation, country } = queryParams;

  const fetchSalaryRangeParams = buildSalaryRangeParams(queryParams);
  const isValidParam =
    occupation && occupation?.trim() !== '' && country.trim() !== '';

  const { data, mutate, isValidating } = useSWR<
    TUseFetchSalaryRangeResponse,
    TFetchSalaryRangeParams
  >(
    isValidParam
      ? {
          key: 'fetchSalaryRange',
          fetchSalaryRangeParams,
        }
      : null,
    () => fetcher(fetchSalaryRangeParams),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      ...configs,
    }
  );

  return {
    data,
    fetcher: mutate,
    loading: loading || isValidating,
  };
};

export default useFetchSalaryRange;
