import React, { useCallback } from 'react';
import { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';
import { getSession } from 'next-auth/react';

import getPermissionsSSR from '@shared/ssrApiCalls/getPermissionsSSR';

import { getInternalMainAppHost } from '@packages/eh-utils/browserEnv';
import { fetchData } from '@shared/utils';

import { getRecommendedJobsSSR } from 'src/modules/CareersPage/components/JobMatches/ssrApiCalls';
import UserProfileHome from 'src/modules/User/components/ProfileHome';
import { EHUserHome } from 'src/modules/User/components/ProfileHome/EHUserHome';
import { TUserProfile } from 'src/modules/User/types';
import UserDashboard from 'src/modules/UserDashboard';
import { MIN_PROFILE_STRENGTH_SCORE } from 'src/constants';

import LandingPage from '../modules/CareersPage/components/LandingPage';

const LANDING_PAGE_TITLE =
  'Jobs Board: Find Thousands of Jobs Listed Worldwide';
const LANDING_PAGE_DESCRIPTION =
  "Swag's ATS jobs board lists thousands of jobs from companies worldwide hiring for remote, hybrid and onsite jobs. Try our ATS today!";
const LANDING_PAGE_META_IMAGE =
  'https://s3.ap-southeast-2.amazonaws.com/addons-assets.employmenthero.com/career-page/master/swag-job-banner.png';

export type HomePageProps = {
  authenticated: boolean;
  firstName?: string;
  profileStrengthScore?: number;
  smartMatchCount?: number;
  isOffBoardEHUser?: boolean;
  isShowCandidateDashboard?: boolean;
};

const HomePage: NextPage<HomePageProps> = ({
  authenticated,
  firstName = '',
  profileStrengthScore,
  smartMatchCount,
  isOffBoardEHUser,
  isShowCandidateDashboard,
}) => {
  const renderChildComponent = useCallback(() => {
    if (!authenticated) return <LandingPage />;

    if (isOffBoardEHUser)
      return (
        <EHUserHome
          smartMatchCount={smartMatchCount}
          firstName={firstName}
          profileStrengthScore={profileStrengthScore}
        />
      );

    if (isShowCandidateDashboard)
      return <UserDashboard firstName={firstName} />;

    return (
      <UserProfileHome
        isLandingPage
        firstName={firstName}
        profileStrengthScore={profileStrengthScore}
        smartMatchCount={smartMatchCount}
      />
    );
  }, [
    authenticated,
    isShowCandidateDashboard,
    firstName,
    isOffBoardEHUser,
    profileStrengthScore,
    smartMatchCount,
  ]);

  return (
    <>
      <Head>
        <title>{LANDING_PAGE_TITLE}</title>
        <meta name="description" content={LANDING_PAGE_DESCRIPTION} />
        {/* For Facebook and Open Graph */}
        <meta property="og:title" content={LANDING_PAGE_TITLE} />
        <meta property="og:description" content={LANDING_PAGE_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={'https://jobs.swagapp.com'} />
        <meta property="og:image" content={LANDING_PAGE_META_IMAGE} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        {/* For Twitter */}
        <meta name="twitter:title" content={LANDING_PAGE_TITLE} />
        <meta name="twitter:description" content={LANDING_PAGE_DESCRIPTION} />
        <meta name="twitter:image" content={LANDING_PAGE_META_IMAGE} />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      {renderChildComponent()}
    </>
  );
};

export const getServerSideProps: GetServerSideProps<
  HomePageProps
> = async context => {
  const { req } = context;
  const session = await getSession({ req });

  if (!session) {
    return {
      props: { authenticated: false },
    };
  }

  const userProfileData = await fetchData<TUserProfile>(
    req,
    `${getInternalMainAppHost()}/api/v3/users/self_profile`
  );
  const recommendJobResponse = await getRecommendedJobsSSR(req);

  const permissions = await getPermissionsSSR(req);

  const permissionData = permissions?.data;
  const userProfile = userProfileData.data;

  const smartMatchCount =
    recommendJobResponse.data?.career_page_jobs.length ?? 0;
  const profileStrengthScore = userProfile?.profile_strength_score ?? 0;
  const firstName = userProfile?.first_name ?? '';

  const isProfilePopulated = Boolean(
    userProfile?.employment_histories?.some(history => history?.is_verified)
  );

  const isOffBoardEHUser = Boolean(
    userProfile?.all_members_terminated &&
      profileStrengthScore <= MIN_PROFILE_STRENGTH_SCORE &&
      !isProfilePopulated
  );

  const isShowCandidateDashboard = Boolean(
    permissionData?.candidate_dashboard_enhancement_enabled
  );

  return {
    props: {
      authenticated: true,
      firstName,
      profileStrengthScore,
      smartMatchCount,
      isOffBoardEHUser,
      isShowCandidateDashboard,
    },
  };
};

export default HomePage;
