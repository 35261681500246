import { useSession } from 'next-auth/react';
import styled from 'styled-components';

import { Box, Grid, SxObject, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { mediaMaxQueries, themeGet } from '@packages/hero-theme/utils';
import LinkButton from '@shared/LinkButton';
import useWindowSize from '@shared/hooks/useWindowSize';
import {
  TRACK_LANDING_PAGE_SMART_MATCH_CTA_CLICK,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

const SmartMatchTitleWrapper = styled.div`
  font-size: ${themeGet('fontSizes.10xlarge')}px;
  line-height: ${themeGet('lineHeights.10xlarge')}px;
  font-weight: ${themeGet('fontWeights.semiBold')};
  margin-bottom: ${themeGet('space.medium')}px;

  ${mediaMaxQueries.xl} {
    font-size: ${themeGet('fontSizes.8xlarge')}px;
    line-height: ${themeGet('lineHeights.8xlarge')}px;
  }

  ${mediaMaxQueries.md} {
    font-size: ${themeGet('fontSizes.6xlarge')}px;
    line-height: ${themeGet('lineHeights.6xlarge')}px;
  }
`;

const ConversationImage = styled(Image)`
  border-radius: ${themeGet('radii.large')}px;
  max-width: 100%;
  max-height: 480px;
  margin-left: ${themeGet('space.large')}px;
  width: initial !important;

  ${mediaMaxQueries.md} {
    margin-left: 0px;
  }

  ${mediaMaxQueries.sm} {
    width: 90% !important;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-left: ${themeGet('space.large')}px;
  padding-right: ${themeGet('space.large')}px;
  text-align: center;

  ${mediaMaxQueries.md} {
    padding-right: 0px;
  }

  ${mediaMaxQueries.sm} {
    padding-right: ${themeGet('space.large')}px;
  }
`;

const generateBackgroundStyles = (showBackground: boolean): SxObject => {
  if (showBackground) {
    return {
      backgroundColor: 'primaryBackground',
      borderRadius: 'large',
    };
  }

  return {};
};

const CareerAdviceBanner = () => {
  const { track } = useMixpanelTracking();
  const { isSmallScreen } = useWindowSize();
  const { status } = useSession();
  const isAuthenticated = status === 'authenticated';

  return (
    <Box
      mt={isSmallScreen ? undefined : 'xxxlarge'}
      mb={isSmallScreen ? undefined : 'xxxxlarge'}
    >
      <Grid sx={{ mb: 'medium', ml: 'medium', mr: 'medium' }}>
        <Grid.Row
          gutter={['medium', 'medium']}
          sx={{
            placeContent: 'center',
            maxHeight: isSmallScreen ? 'initial' : 440,
            backgroundImage: isSmallScreen
              ? 'initial'
              : "url('/assets/images/fireworks-smart-match.svg')",
            backgroundPosition: 'top right',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '50%',
            flexDirection: isSmallScreen ? 'column-reverse' : 'initial',
            ...generateBackgroundStyles(!isSmallScreen),
          }}
        >
          <Grid.Col
            span={[24, 24, 12, 12, 12]}
            sx={{
              alignSelf: 'center',
              mt: 'large',
              mb: 'large',
              ...generateBackgroundStyles(isSmallScreen),
            }}
          >
            <Box p="xlarge">
              <SmartMatchTitleWrapper>
                Find your perfect match
              </SmartMatchTitleWrapper>

              <Typography.Text fontSize={16} sx={{ mb: 'xlarge' }}>
                We reverse the traditional job search and present your profile
                to our network of 200k+ employers. Don&#39;t spend hours
                searching through job boards for the perfect fit — sit back as
                we match your skills and experience to great roles.
              </Typography.Text>

              <LinkButton
                text="Start now"
                href={isAuthenticated ? '/user/job_matches' : '/setup_profile'}
                size="large"
                data-test-id="smart-match-start-button"
                onClick={() => {
                  track(TRACK_LANDING_PAGE_SMART_MATCH_CTA_CLICK, {
                    logged_in: isAuthenticated,
                  });
                }}
              />
            </Box>
          </Grid.Col>

          <Grid.Col span={[24, 20, 12, 12, 12]} sx={{ alignSelf: 'center' }}>
            <ImageWrapper>
              <Box p="medium">
                <ConversationImage
                  src="/assets/images/smart-match-user.png"
                  alt="smart match user standing confidently"
                  layout="responsive"
                />
              </Box>

              <Image
                src="/assets/images/job-match-button.svg"
                alt="setup job match button"
                style={{
                  position: 'absolute',
                  left: '60%',
                  top: '0%',
                  width: '40%',
                  maxWidth: 200,
                }}
              />

              <Image
                src="/assets/images/dream-job.svg"
                alt="dream job"
                style={{
                  position: 'absolute',
                  left: '10%',
                  top: '85%',
                  width: '50%',
                  maxWidth: 280,
                }}
              />

              <Image
                src="/assets/images/jumping-over-search.svg"
                alt="person pole vaulting over search"
                style={{
                  position: 'absolute',
                  left: '15%',
                  top: '0%',
                  width: '20%',
                }}
              />
            </ImageWrapper>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Box>
  );
};

export default CareerAdviceBanner;
