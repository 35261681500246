import { JobItem } from '../../types/index';

import { LANDING_PAGE_SIZE } from '../../../../constants';

export const getHideLoadMore = ({
  isNoJobs,
  jobsRawData,
}: {
  isNoJobs: boolean;
  jobsRawData: JobItem[][] | undefined;
}) =>
  isNoJobs ||
  (jobsRawData &&
    jobsRawData[jobsRawData.length - 1]?.length < LANDING_PAGE_SIZE);

export const getIsLoadingMore = ({
  isFetchingJobs,
  jobsRawData,
  size,
}: {
  isFetchingJobs: boolean;
  jobsRawData: JobItem[][] | undefined;
  size: number;
}) =>
  isFetchingJobs ||
  (size > 0 && jobsRawData && typeof jobsRawData[size - 1] === 'undefined');

export const filterCountryOptions = (searchQueries: Record<string, string>) =>
  searchQueries.countryCode === 'anywhere'
    ? { ...searchQueries, countryCode: undefined }
    : searchQueries;
