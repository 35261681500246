import React from 'react';
import styled, { useTheme } from 'styled-components';

import { Box, Spinner, Typography } from '@hero-design/react';

import CompanyLogo from 'src/modules/CareersPage/components/shared/RedesignRoleItem/CompanyLogo';
import useFetchRecruitmentSettings from 'src/modules/CareersPage/hooks/useFetchRecruitmentSettings';
import { NOTIFICATION_TYPE } from 'src/modules/notifications/constants';
import { TNotificationType } from 'src/modules/notifications/types';
import { formatRelativeTime } from 'src/modules/User/components/shared/date';

type NotificationItemLayoutProps = {
  type: TNotificationType;
  message: string | React.ReactNode;
  createdAt: string;
  loading?: boolean;
  organisationId: string;
  onNavigate: () => void;
};

const MessageWrapper = styled(Typography.Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  hyphens: manual;
`;

const NotificationItemLayout = ({
  type,
  message,
  createdAt,
  loading = false,
  onNavigate,
  organisationId,
}: NotificationItemLayoutProps) => {
  const theme = useTheme();
  const { recruitmentSettings } = useFetchRecruitmentSettings(organisationId);

  const companyName = recruitmentSettings?.data.company_name;
  const companyLogo = recruitmentSettings?.data.recruitment_logo?.logo_url;
  const formattedCreatedAtDate = formatRelativeTime(new Date(createdAt));

  const notificationTypeTitle = () => {
    switch (type) {
      case NOTIFICATION_TYPE.ATS_DIRECT_MESSAGE:
        return 'You have a new message';
      case NOTIFICATION_TYPE.ATS_CANDIDATE_SHORTLISTED:
        return 'You have been matched with a top role!';
      case NOTIFICATION_TYPE.ATS_CANDIDATE_MOVEMENT:
        return 'Application update';
      default:
        return '';
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Spinner loading={loading}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: `${theme.space.medium}px`,
            width: '100%',
          }}
        >
          <Box borderRadius="large">
            <CompanyLogo
              companyName={companyName}
              companyLogo={companyLogo}
              extraStyles={{
                width: 70,
                height: 70,
                borderRadius: '100%',
                backgroundColor: 'defaultGreyBackground',
              }}
            />
          </Box>
          <Box
            onClick={onNavigate}
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <Typography.Text
                fontWeight="semi-bold"
                sx={{ mr: 'small', wordBreak: 'break-word' }}
              >
                {notificationTypeTitle()}
              </Typography.Text>
              <Typography.Text intent="subdued" fontSize={12}>
                {formattedCreatedAtDate}
              </Typography.Text>
            </Box>

            <MessageWrapper fontSize={12}>{message}</MessageWrapper>
          </Box>
        </Box>
      </Spinner>
    </Box>
  );
};

export default NotificationItemLayout;
