import isNil from 'lodash/fp/isNil';

import { useState } from 'react';

import { useFetch } from '@packages/eh-utils/api';
import { ApiConfigs } from '@packages/eh-utils/api/types';
import { getNotificationServiceDirectApiHost } from '@packages/eh-utils/browserEnv';
import { camelizeKeys } from '@packages/eh-utils/formatObject';

import {
  TNotificationSupportedData,
  TNotificationType,
  TParsedNotificationSupportedData,
} from '../types';

const LIMIT = 10;

type TGetListNotificationParams = {
  pillar: 'job';
  before_id?: number | null;
  limit: number;
  type: TNotificationType[];
};

type TGetListNotificationResponse = {
  data: {
    items: Array<TNotificationSupportedData>;
    last_notification_local_id: number | null;
  };
};

type TGetListNotificationProps = Pick<
  ApiConfigs<TGetListNotificationResponse, TGetListNotificationParams>,
  'onCompleted' | 'onFailed'
>;

const useGetListNotification = ({
  onCompleted,
  onFailed,
}: TGetListNotificationProps = {}) => {
  const [beforeId, setBeforeId] = useState<number | null>(null);
  const [isReachedEnd, setIsReachedEnd] = useState(false);
  const [notificationListData, setNotificationListData] = useState<
    TParsedNotificationSupportedData[]
  >([]);

  const { fetcher, loading } = useFetch<
    TGetListNotificationResponse,
    TGetListNotificationParams
  >({
    endpoint: `${getNotificationServiceDirectApiHost()}/api/web/v1/notifications`,
    onCompleted: res => {
      const { data: resData } = res;

      if (isNil(resData.last_notification_local_id)) {
        setIsReachedEnd(true);
      }

      setBeforeId(resData.last_notification_local_id);

      const formattedData = resData.items.map<TParsedNotificationSupportedData>(
        item =>
          camelizeKeys(item) as unknown as TParsedNotificationSupportedData
      );

      setNotificationListData([...notificationListData, ...formattedData]);

      onCompleted?.(res);
    },
    onFailed,
  });

  const loadNotification = (type: TNotificationType[]) => {
    if (isReachedEnd) {
      return;
    }

    fetcher({
      pillar: 'job',
      before_id: beforeId,
      limit: LIMIT,
      type,
    });
  };

  return {
    loadNotification,
    loadingNotificaton: loading,
    notificationListData,
    isReachedEnd,
  };
};

export default useGetListNotification;
