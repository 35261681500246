import styled from 'styled-components';

import { mediaMaxQueries, themeGet } from '@packages/hero-theme/utils';

const RebrandingWrapper = styled.div`
  max-width: 1300px;
  padding-left: ${themeGet('space.xlarge')}px;
  padding-right: ${themeGet('space.xlarge')}px;

  ${mediaMaxQueries.md} {
    padding-left: ${themeGet('space.medium')}px;
    padding-right: ${themeGet('space.medium')}px;
  }

  &&& > div {
    padding-top: ${themeGet('space.xxxlarge')}px;
    padding-bottom: ${themeGet('space.xxxlarge')}px;

    ${mediaMaxQueries.md} {
      padding-top: ${themeGet('space.xlarge')}px;
      padding-bottom: ${themeGet('space.xlarge')}px;
    }
  }
`;

export default RebrandingWrapper;
