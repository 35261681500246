import styled from 'styled-components';

import { Box, Grid, Typography } from '@hero-design/react';

import Image from '@packages/eh-adapter/image';
import { mediaMaxQueries, themeGet } from '@packages/hero-theme/utils';
import LinkButton from '@shared/LinkButton';
import {
  TRACK_LANDING_PAGE_CALCULATE_POTENTIAL_TILE_CTA_CLICK,
  useMixpanelTracking,
} from '@shared/trackTools/mixpanel';

const ProfileTitleWrapper = styled.div`
  font-size: ${themeGet('fontSizes.10xlarge')}px;
  line-height: ${themeGet('lineHeights.10xlarge')}px;
  font-weight: ${themeGet('fontWeights.semiBold')};
  margin-bottom: ${themeGet('space.medium')}px;

  ${mediaMaxQueries.xl} {
    font-size: ${themeGet('fontSizes.8xlarge')}px;
    line-height: ${themeGet('lineHeights.8xlarge')}px;
  }

  ${mediaMaxQueries.md} {
    font-size: ${themeGet('fontSizes.6xlarge')}px;
    line-height: ${themeGet('lineHeights.6xlarge')}px;
  }
`;

const ProfileImage = styled(Image)`
  border-radius: ${themeGet('radii.large')}px;
  max-width: 100%;
  max-height: 440px;
  margin-left: ${themeGet('space.large')}px;
  width: initial !important;

  ${mediaMaxQueries.md} {
    margin-left: 0px;
  }

  ${mediaMaxQueries.sm} {
    width: 90% !important;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-left: ${themeGet('space.large')}px;
  padding-right: ${themeGet('space.large')}px;
  text-align: center;

  ${mediaMaxQueries.md} {
    padding-right: 0px;
  }

  ${mediaMaxQueries.sm} {
    padding-right: ${themeGet('space.large')}px;
  }
`;

const SwagProfileBanner = () => {
  const { track } = useMixpanelTracking();

  return (
    <Grid>
      <Grid.Row gutter={['medium', 'medium']} sx={{ placeContent: 'center' }}>
        <Grid.Col span={[24, 20, 12, 12, 12]} sx={{ mb: 'medium' }}>
          <ImageWrapper>
            <ProfileImage
              src="/assets/images/smartmatch_potential_feature.png"
              alt="swag features"
              layout="responsive"
            />
          </ImageWrapper>
        </Grid.Col>

        <Grid.Col span={[24, 24, 12, 12, 12]} sx={{ alignSelf: 'center' }}>
          <Box pl="xlarge" pr="xlarge">
            <ProfileTitleWrapper>
              Should you be earning more?
            </ProfileTitleWrapper>

            <Typography.Text fontSize={16}>
              Upload your CV to uncover your true earning potential and find
              your next career move. We analyse your skills, experience and
              qualifications to generate personalised career insights and
              recommendations.
            </Typography.Text>
            <Typography.Text fontSize={16} sx={{ mb: 'xlarge' }}>
              Powered by Employment Hero&#39;s database of 300K+ organisations;
              our insights are backed by real data.
            </Typography.Text>
            <LinkButton
              text="Calculate my potential"
              href="/smartmatch_potential"
              size="large"
              data-test-id="swag-profile-start-button"
              onClick={() => {
                track(TRACK_LANDING_PAGE_CALCULATE_POTENTIAL_TILE_CTA_CLICK, {
                  redirect_url: '/smartmatch_potential',
                  smartmatch_potential_enabled: true,
                });
              }}
            />
          </Box>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default SwagProfileBanner;
