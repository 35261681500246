import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { Portlet } from '@hero-design/react';

import { NOTIFICATION_TYPE } from 'src/modules/notifications/constants';
import useGetListNotification from 'src/modules/notifications/hooks/useGetListNotification';

import NotificationItems from './NotificationItems';
import NotificationSettingsModal from './NotificationSettingsModal';

const NotificationFeed = () => {
  const { notificationListData, loadNotification, loadingNotificaton } =
    useGetListNotification();
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    loadNotification([
      NOTIFICATION_TYPE.ATS_DIRECT_MESSAGE,
      NOTIFICATION_TYPE.ATS_CANDIDATE_SHORTLISTED,
      NOTIFICATION_TYPE.ATS_CANDIDATE_MOVEMENT,
    ]);
  }, []);

  return (
    <>
      <Portlet
        title="Notification feed"
        sx={{
          boxShadow: `0px 2px 4px ${theme.colors.black}1F`,
        }}
        actions={[
          {
            icon: 'cog',
            onClick: () => {
              setIsModalOpen(true);
            },
            'data-test-id': 'notification-setting-button',
          },
        ]}
        loading={loadingNotificaton}
      >
        {notificationListData.map(item => (
          <NotificationItems key={item.id} data={item} />
        ))}
      </Portlet>
      {isModalOpen && (
        <NotificationSettingsModal onClose={() => setIsModalOpen(false)} />
      )}
    </>
  );
};

export default NotificationFeed;
