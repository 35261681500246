import { useFetch } from '@packages/eh-utils/api';
import { ApiConfigs } from '@packages/eh-utils/api/types';
import { getNotificationServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

type TUpdateCandidateNotifcationSettingsParams = {
  atsDirectMessageSent: string[];
};

type TUpdateCandidateNotificationSettingsResponse = unknown;

type TUpdateCandidateNotificationSettingsProps = Pick<
  ApiConfigs<
    TUpdateCandidateNotificationSettingsResponse,
    TUpdateCandidateNotifcationSettingsParams
  >,
  'onCompleted' | 'onFailed'
>;

const useUpdateCandidateNotificationSettings = ({
  onCompleted,
  onFailed,
}: TUpdateCandidateNotificationSettingsProps) => {
  const { fetcher, loading } = useFetch<
    TUpdateCandidateNotificationSettingsResponse,
    TUpdateCandidateNotifcationSettingsParams
  >({
    method: 'PUT',
    endpoint: `${getNotificationServiceDirectApiHost()}/api/v1/user-settings/delivery`,
    formatBody: params => {
      const deliveryParams = {
        delivery_settings: {
          ats_direct_message: params?.atsDirectMessageSent || [],
        },
      };

      return JSON.stringify(deliveryParams);
    },
    onCompleted,
    onFailed,
  });

  return {
    fetcher,
    loading,
  };
};

export default useUpdateCandidateNotificationSettings;
